import React, { useEffect, useState, useRef } from "react";
import instagramlogo from "../assets/instagram.png";
import facebooklogo from "../assets/facebooklogo.png";
import iconChat from "../assets/iconChat.png";
import "./Instagram.css";

const Instagram = () => {
  const [clickChat, setClickChat] = useState(false);
  const wrapperRef = useRef();
  const chatContainerRef = useRef();

  const handleToggleChat = () => {
    setClickChat(!clickChat);
  };

  const handleClickOutside = (event) => {
    if (
      chatContainerRef.current &&
      !chatContainerRef.current.contains(event.target) &&
      !wrapperRef.current.contains(event.target)
    ) {
      setClickChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <button ref={wrapperRef} className="iconChat" onClick={handleToggleChat}>
        <img src={iconChat} alt="Chat Icon" />
      </button>
      <div
        ref={chatContainerRef}
        className={`chat-icons-container ${clickChat ? "" : "hidden-chat"}`}
      >
        <button
          className="instagram-chat"
          onClick={() =>
            window.open("https://ig.me/m/wood_art_oficial", "_blank")
          }
        >
          <img src={instagramlogo} alt="Instagram Logo" />
        </button>
        <button
          className="facebook-chat"
          onClick={() =>
            window.open("https://m.me/61566842943976", "_blank")
          }
        >
          <img src={facebooklogo} alt="Facebook Logo" />
        </button>
      </div>
    </>
  );
};

export default Instagram;

