

import banner2 from "../assets/EspacioTendenciaMobile.jpg"
import bannerMobile from "../assets/EspacioTendencia.jpg"
import "../App.css";
// import Header from "./Header.js";
import Card from "./Card.js";
import data from "../data/data.js";
import { Carousel } from "flowbite-react";


function Home() {
  return (
    <section className="Home">
      {/* <Header> </Header> */}
      <section className="carousel-desktop">
      <Carousel>
      <img className="banner" src={banner2} alt="banner" loading="lazy"/>
      </Carousel>
      </section>
      <section className="carousel-mobile">
      <Carousel>
      <img className="banner" src={bannerMobile} alt="banner" loading="lazy"/>
      </Carousel>
      </section>
    
      <div className="body-container">
        <div className="product-container">
          <h1>Productos</h1>
          <p className="descripcion">
            Lleva tu estilo y crea espacios interiores modernos y elegantes con
            los nuevos paneles de madera WPC, SPC y LVT.
          </p>
          <div className={"product-card-container"}>
            {data.categories.map((category) => (
              <Card key={category.id} product={category} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
